import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./BlogDetails.module.css";
import { urlFor } from "../sanityClient";

const BlogDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const blog = location.state?.blog;
  const sectionRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const topRef = useRef(null); // Ref for the top of the blog details container

  useEffect(() => {
    if (blog) {
      sectionRefs.current = sectionRefs.current.slice(0, blog.content.length);
      // Scroll to top when the blog object changes
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [blog]);

  const scrollToSection = (index) => {
    sectionRefs.current[index].scrollIntoView({ behavior: "smooth" });
    setActiveIndex(index);
  };

  if (!blog) {
    return (
      <div>
        Blog not found. <button onClick={() => navigate("/")}>Go back</button>
      </div>
    );
  }

  return (
    <div className={styles.blogDetailsContainer}>
      <div ref={topRef} /> {/* Empty div with topRef */}
      <div className={styles.blogImageContainer}>
        {blog.image ? (
          <img
            className={styles.blogImage}
            src={urlFor(blog.image).url()} // Use urlFor to get the image URL
            alt={blog.header}
          />
        ) : (
          <p>No Image Available</p>
        )}
        <div className={styles.blogInfo}>
          <h1 className={styles.blogTitle}>{blog.header}</h1>
          <div className={styles.blogMeta}>
            <span>{blog.readTime}</span>
            <span> {new Date(blog.date).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
      <div className={styles.blogContent}>
        <div className={styles.articleSection}>
          {blog.content.map((section, index) => (
            <div
              key={index}
              ref={(el) => (sectionRefs.current[index] = el)}
              className={styles.section}
            >
              <h2 className={styles.subTitle}>{section.subTitle}</h2>
              <p className={styles.paragraph}>{section.paragraph}</p>
            </div>
          ))}
        </div>
        <div className={styles.articleNav}>
          <h3 className={styles.articleHead}>In this article</h3>
          <ul className={styles.articleNavList}>
            {blog.content.map((section, index) => (
              <li key={index} className={styles.navItem}>
                <p
                  onClick={() => scrollToSection(index)}
                  className={`${styles.navLink} ${
                    activeIndex === index ? styles.activeNavLink : ""
                  }`}
                >
                  {section.subTitle}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <hr className={styles.hrLine} />
    </div>
  );
};

export default BlogDetails;
