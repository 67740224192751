// import React, { useState } from "react";
// import styles from "./MacroServiceHome.module.css";
// import image from "./macroImg.png"; // Ensure this path is correct
// import arrow from "./arrow-right.png";

// const MacroService = () => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const handleToggle = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <div className={styles.macroService}>
//       <div className={styles.leftCol}>
//         <div className={styles.badge}>OUR MACRO SERVICES</div>
//         <div className={styles.description}>
//           We offer a range of freight forwarding solutions such as documentation
//           & insurance to inland transportation for your supply chain network.
//         </div>
//         <div className={styles.imagesContainer}>
//           {[1, 2, 3].map((_, index) => (
//             <div key={index} className={styles.imageWrapper}>
//               <img
//                 src={image}
//                 alt={`service-${index + 1}`}
//                 className={styles.image}
//               />
//               <div className={styles.imageText}>
//                 <h3>Image Heading {index + 1}</h3>
//                 <p>This is a short paragraph for image {index + 1}.</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className={styles.rightCol}>
//         {isExpanded ? (
//           <div className={styles.imagesContainer}>
//             {[4, 5].map((_, index) => (
//               <div key={index} className={styles.imageWrapper}>
//                 <img
//                   src={image}
//                   alt={`service-${index + 4}`}
//                   className={styles.image}
//                 />
//                 <div className={styles.imageText}>
//                   <h3>Image Heading {index + 4}</h3>
//                   <p>This is a short paragraph for image {index + 4}.</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className={styles.rightDescription}>
//             From meticulous documentation management and comprehensive insurance
//             options to efficient customs clearance and tailored packaging
//             solutions, we streamline every aspect of your logistics journey.
//             From meticulous documentation management and comprehensive insurance
//             options to efficient customs clearance and tailored packaging
//             solutions, we streamline every aspect of your logistics journey.
//           </div>
//         )}
//         <div className={styles.viewMoreBadge} onClick={handleToggle}>
//           {isExpanded ? "View Less" : "View All Services"}{" "}
//           <img src={arrow} alt="arrow" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MacroService;

import React, { useEffect, useState } from "react";
import styles from "./MacroServiceHome.module.css";
import client, { urlFor } from "../../sanityClient"; // Adjust path as necessary

const MacroServiceHome = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `
          *[_type == "macroServiceHome"][0] {
            badge,
            description,
            images[] {
              image,
              heading,
              text
            },
            rightDescription
          }
        `;
        const result = await client.fetch(query);
        setData(result);
      } catch (err) {
        setError("Failed to fetch data");
        console.error("Failed to fetch data", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div></div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!data) return <div className={styles.noData}>No Data Available</div>;

  const initialImages = data.images.slice(0, 3);
  const additionalImages = data.images.slice(3);

  return (
    <>
      <div className={styles.macroServiceHeader}>
        <div className={styles.badge}>{data.badge}</div>
        <div className={styles.description}>{data.description}</div>
      </div>
      <div className={styles.macroService}>
        <div className={styles.leftCol}>
          <div className={styles.imagesContainer}>
            {initialImages.map((item, index) => (
              <div key={index} className={styles.imageWrapper}>
                <img
                  src={urlFor(item.image).url()}
                  alt={`service-${index}`}
                  className={styles.image}
                />
                <div className={styles.imageText}>
                  <h3>{item.heading}</h3>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
          {showMore && (
            <div className={styles.imagesContainer}>
              {additionalImages.slice(0, 3).map((item, index) => (
                <div key={index} className={styles.imageWrapper}>
                  <img
                    src={urlFor(item.image).url()}
                    alt={`service-more-${index}`}
                    className={styles.image}
                  />
                  <div className={styles.imageText}>
                    <h3>{item.heading}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.rightCol}>
          {showMore ? (
            <>
              <div className={styles.rightColImages}>
                {additionalImages.slice(3, 5).map((item, index) => (
                  <div key={index + 3} className={styles.imageWrapper}>
                    <img
                      src={urlFor(item.image).url()}
                      alt={`service-more-${index + 3}`}
                      className={styles.image}
                    />
                    <div className={styles.imageText}>
                      <h3>{item.heading}</h3>
                      <p>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.rightColImages}>
                {additionalImages.slice(5).map((item, index) => (
                  <div key={index + 5} className={styles.imageWrapper}>
                    <img
                      src={urlFor(item.image).url()}
                      alt={`service-more-${index + 5}`}
                      className={styles.image}
                    />
                    <div className={styles.imageText}>
                      <h3>{item.heading}</h3>
                      <p>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className={styles.rightDescription}>
              {data.rightDescription}
            </div>
          )}
          <div
            className={styles.viewMoreBadge}
            onClick={() => setShowMore((prev) => !prev)}
          >
            {showMore ? "View Less" : "View All Services"}
          </div>
        </div>
      </div>
    </>
  );
};

export default MacroServiceHome;
