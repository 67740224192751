// import React, { useState, useEffect } from "react";
// import styles from "./Work.module.css";
// import workIcon from "./workImg.png";
// import workSlide from "./workSlider.png";
// import workSlide2 from "./workSlider2.png";

// const Work = () => {
//   const images = [workSlide, workSlide2, workSlide]; // Replace with actual image paths
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000); // Change image every 3 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className={styles.workContainer}>
//       <div className={styles.workDiv}>
//         <div className={styles.badge}>HOW WE WORK</div>
//         <h1 className={styles.header}>
//           Simplify Your Shipping Experience with Our Easy Step Process
//         </h1>
//         <div className={styles.row}>
//           <div className={styles.imageContainer}>
//             <img
//               src={images[currentImageIndex]}
//               alt="Work"
//               className={styles.image}
//             />
//           </div>
//           <div className={styles.infoContainer}>
//             <div className={styles.infoRow}>
//               <img src={workIcon} className={styles.icon} alt="work" />
//               <div>
//                 <h2 className={styles.sideHead}>Customs Paperwork</h2>
//                 <p className={styles.sideDesc}>
//                   First, we meticulously handle customs paperwork, ensuring
//                   compliance and swift processing for seamless logistics
//                   operations.
//                 </p>
//               </div>
//             </div>
//             <div className={styles.infoRow}>
//               <img src={workIcon} className={styles.icon} alt="work" />
//               <div>
//                 <h2 className={styles.sideHead}>Transportation</h2>
//                 <p className={styles.sideDesc}>
//                   First, we meticulously handle customs paperwork, ensuring
//                   compliance and swift processing for seamless logistics
//                   operations.
//                 </p>
//               </div>
//             </div>
//             <div className={styles.infoRow}>
//               <img src={workIcon} className={styles.icon} alt="work" />
//               <div>
//                 <h2 className={styles.sideHead}>Forwarding</h2>
//                 <p className={styles.sideDesc}>
//                   First, we meticulously handle customs paperwork, ensuring
//                   compliance and swift processing for seamless logistics
//                   operations.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Work;

import imageUrlBuilder from "@sanity/image-url";
import client from "../../sanityClient";
import { useEffect, useState } from "react";
import styles from "./Work.module.css";

const builder = imageUrlBuilder(client);

const urlFor = (source) => builder.image(source);

const Work = () => {
  const [data, setData] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "workContent"]';
      const result = await client.fetch(query);
      setData(result[0]); // Assuming you only have one document
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.images && data.images.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % data.images.length
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [data]);

  if (!data) {
    return <div></div>;
  }

  const images = data.images.map((img) => urlFor(img).url());

  return (
    <div id="work" className={styles.workContainer}>
      <div className={styles.workDiv}>
        <div className={styles.badge}>{data.badge}</div>
        <h1 className={styles.header}>{data.header}</h1>
        <div className={styles.row}>
          <div className={styles.imageContainer}>
            <img
              src={images[currentImageIndex]}
              alt="Work"
              className={styles.image}
            />
          </div>
          <div className={styles.infoContainer}>
            {data.infoRows.map((info, index) => (
              <div className={styles.infoRow} key={index}>
                <img
                  src={urlFor(info.icon).url()}
                  className={styles.icon}
                  alt="work"
                />
                <div>
                  <h2 className={styles.sideHead}>{info.title}</h2>
                  <p className={styles.sideDesc}>{info.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
