// import React from "react";
// import styles from "./ServiceHero.module.css";
// import serviceHero from "./serviceHero.png";

// const ServiceHero = () => {
//   return (
//     <section className={styles.heroSection}>
//       <img src={serviceHero} alt="Hero" className={styles.heroImage} />
//       <div className={styles.scrollContainer}>
//         <div className={styles.dots}>
//           <span className={`${styles.dot} ${styles.dot1}`}></span>
//           <span className={`${styles.dot} ${styles.dot2}`}></span>
//           <span className={`${styles.dot} ${styles.dot3}`}></span>
//         </div>
//         <p className={styles.scrollText}>Scroll Down</p>
//       </div>
//     </section>
//   );
// };

// export default ServiceHero;

import React, { useEffect, useState } from "react";
import styles from "./ServiceHero.module.css";
import client from "../../sanityClient"; // Adjust path as necessary
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

const urlFor = (source) => builder.image(source);

const ServiceHero = () => {
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const data = await client.fetch(
          `*[_type == "serviceHero"]{heroImage, scrollText, dots}`
        );
        setHeroData(data[0]);
      } catch (error) {
        console.error("Error fetching hero data:", error);
      }
    };

    fetchHeroData();
  }, []);

  if (!heroData) {
    return <div></div>;
  }

  return (
    <section className={styles.heroSection}>
      <img
        src={urlFor(heroData.heroImage).url()}
        alt="Hero"
        className={styles.heroImage}
      />
      {/* <div className={styles.scrollContainer}>
        <div className={styles.dots}>
          {heroData.dots.map((dot, index) => (
            <span
              key={index}
              className={`${styles.dot} ${styles[`dot${index + 1}`]}`}
            ></span>
          ))}
        </div>
        <p className={styles.scrollText}>{heroData.scrollText}</p>
      </div> */}
    </section>
  );
};

export default ServiceHero;
