// import React, { useState } from "react";
// import styles from "./About.module.css";
// import img1 from "./abtimg1.png";
// import img2 from "./abtimg2.png";
// import img3 from "./abtimg3.png";

// const About = () => {
//   const tabs = [
//     {
//       id: 1,
//       image: img1,
//       label: "About Us",
//       description:
//         "Established in 2011, our company has consistently delivered top-notch logistics services, earning a stellar reputation in the industry. Our team of highly skilled professionals is committed to providing exceptional support for all your logistics needs, from air and sea freight to domestic shipping and cross-country trucking. We pride ourselves on our strong relationships with all major airlines and shipping lines, ensuring seamless and efficient transport solutions. Our warehousing facilities, both short-term and long-term, are designed to meet the highest standards, providing you with the flexibility and security you need.",
//     },
//     {
//       id: 2,
//       image: img2,
//       label: "Global Presence",
//       description:
//         "Established in 2011, our company has consistently delivered top-notch logistics services, earning a stellar reputation in the industry. Our team of highly skilled professionals is committed to providing exceptional support for all your logistics needs, from air and sea freight to domestic shipping and cross-country trucking. We pride ourselves on our strong relationships with all major airlines and shipping lines, ensuring seamless and efficient transport solutions. Our warehousing facilities, both short-term and long-term, are designed to meet the highest standards, providing you with the flexibility and security you need.",
//     },
//     {
//       id: 3,
//       image: img3,
//       label: "ESG",
//       description:
//         "Established in 2011, our company has consistently delivered top-notch logistics services, earning a stellar reputation in the industry. Our team of highly skilled professionals is committed to providing exceptional support for all your logistics needs, from air and sea freight to domestic shipping and cross-country trucking. We pride ourselves on our strong relationships with all major airlines and shipping lines, ensuring seamless and efficient transport solutions. Our warehousing facilities, both short-term and long-term, are designed to meet the highest standards, providing you with the flexibility and security you need.",
//     },
//   ];

//   const [activeTab, setActiveTab] = useState(tabs[0]);

//   return (
//     <div className={styles.aboutUs}>
//       <div className={styles.leftColumn}>
//         <div className={styles.badge}>About Us</div>
//         <img
//           src={activeTab.image}
//           alt={activeTab.label}
//           className={styles.image}
//         />
//       </div>
//       <div className={styles.rightColumn}>
//         <h1 className={styles.header}>
//           Logistic company that puts customer cargo & delivery first!
//         </h1>
//         <h2 className={styles.subHeader}>
//           Abravic, a new age technology backed Freight management service which
//           provides constant shipment updates and status digitally, making supply
//           chain more affordable, transparent & timely manner across the globe.
//         </h2>
//         <div className={styles.tabs}>
//           {tabs.map((tab) => (
//             <button
//               key={tab.id}
//               className={`${styles.tabButton} ${
//                 activeTab.id === tab.id ? styles.active : ""
//               }`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab.label}
//             </button>
//           ))}
//         </div>
//         <p className={styles.description}>{activeTab.description}</p>
//       </div>
//     </div>
//   );
// };

// export default About;

import React, { useState, useEffect } from "react";
import client from "../../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import styles from "./About.module.css";

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const About = () => {
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [badgeText, setBadgeText] = useState("");
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");

  useEffect(() => {
    client
      .fetch(
        `*[_type == "about"]{
          tabs[]{id, image, label, description},
          badgeText,
          header,
          subHeader
        }`
      )
      .then((data) => {
        if (data.length > 0) {
          setTabs(data[0].tabs);
          setActiveTab(data[0].tabs[0]);
          setBadgeText(data[0].badgeText);
          setHeader(data[0].header);
          setSubHeader(data[0].subHeader);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  if (!activeTab) {
    return <div></div>;
  }

  return (
    <div id="about-us" className={styles.aboutUs}>
      <div className={styles.leftColumn}>
        <div className={styles.badge}>{badgeText}</div>
        <img
          src={urlFor(activeTab.image).url()}
          alt={activeTab.label}
          className={styles.image}
        />
      </div>
      <div className={styles.rightColumn}>
        <h1 className={styles.header}>{header}</h1>
        <h2 className={styles.subHeader}>{subHeader}</h2>
        <div className={styles.tabs}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`${styles.tabButton} ${
                activeTab.id === tab.id ? styles.active : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <p className={styles.description}>{activeTab.description}</p>
      </div>
    </div>
  );
};

export default About;
