import React from "react";
import BlogDetails from "./BlogDetails";
import Blogs from "../serviceDetail/blogs/Blogs";
import Footer from "../content/footer/Footer";

const BlogDetailsPage = () => {
  return (
    <div>
      <BlogDetails />
      <Blogs />
      <Footer />
    </div>
  );
};

export default BlogDetailsPage;
