// import React, { useState, useEffect, useRef } from "react";
// import styles from "./Industries.module.css"; // Import the CSS module

// const industriesData = [
//   {
//     title: "Oil & Gas",
//     description:
//       "We provide robust logistics solutions for the oil and gas industry, ensuring safe and timely delivery of critical equipment and materials.",
//   },
//   {
//     title: "Natural & Processed food",
//     description:
//       "We provide robust logistics solutions for the oil and gas industry, ensuring safe and timely delivery of critical equipment and materials.",
//   },
//   {
//     title: "Metals & Mining",
//     description:
//       "We provide robust logistics solutions for the oil and gas industry, ensuring safe and timely delivery of critical equipment and materials.",
//   },
//   {
//     title: "Garments",
//     description:
//       "We provide robust logistics solutions for the oil and gas industry, ensuring safe and timely delivery of critical equipment and materials.",
//   },
//   { title: "Industry 5", description: "Description for Industry 5" },
//   { title: "Industry 6", description: "Description for Industry 6" },
//   { title: "Industry 7", description: "Description for Industry 7" },
//   { title: "Industry 8", description: "Description for Industry 8" },
//   // Add more industries as needed
// ];

// const Industries = () => {
//   const [visibleItems, setVisibleItems] = useState(4);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const handleScroll = () => {
//       const container = containerRef.current;
//       if (
//         container.scrollHeight - container.scrollTop <=
//         container.clientHeight
//       ) {
//         setVisibleItems((prev) => Math.min(prev + 4, industriesData.length));
//       }
//     };

//     const container = containerRef.current;
//     container.addEventListener("scroll", handleScroll);

//     return () => container.removeEventListener("scroll", handleScroll);
//   }, []);

//   return (
//     <div className={styles.container}>
//       <div className={styles.badge}>
//         <h1>INDUSTRIES SERVED</h1>
//       </div>
//       <div className={styles.paraDiv}>
//         <p className={styles.para}>
//           Our experience spans a variety of industries, enabling us to
//           understand and cater to the unique logistics needs of each sector. We
//           pride ourselves on providing personalized, efficient solutions that
//           help our clients thrive.
//         </p>
//       </div>
//       <div className={styles.content} ref={containerRef}>
//         {industriesData.slice(0, visibleItems).map((industry, index) => (
//           <React.Fragment key={index}>
//             <div className={styles.industry}>
//               <div className={styles.title}>{industry.title}</div>
//               <div className={styles.description}>{industry.description}</div>
//             </div>
//             <hr className={styles.divider} />
//           </React.Fragment>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Industries;

import React, { useState, useEffect, useRef } from "react";
import styles from "./Industries.module.css"; // Import the CSS module
import client from "../../sanityClient"; // Adjust the import path as needed

const Industries = () => {
  const [industriesData, setIndustriesData] = useState(null);
  const [visibleItems, setVisibleItems] = useState(4);
  const containerRef = useRef(null);

  useEffect(() => {
    // Fetch industries and section content from Sanity
    const fetchIndustriesData = async () => {
      try {
        const [data] = await client.fetch(`*[_type == "industriesPage"]{
          badgeText,
          paragraphText,
          industries[]{
            title,
            description
          }
        }`);
        setIndustriesData(data);
      } catch (error) {
        console.error("Failed to fetch industries data", error);
      }
    };

    fetchIndustriesData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container && industriesData && industriesData.industries) {
        const nearBottom =
          container.scrollHeight - container.scrollTop <=
          container.clientHeight + 10;
        if (nearBottom) {
          setVisibleItems((prev) =>
            Math.min(prev + 4, industriesData.industries.length)
          );
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [industriesData]);

  if (!industriesData || !industriesData.industries) {
    return <div></div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.badge}>
        <h1>{industriesData.badgeText || "Default Badge Text"}</h1>
      </div>
      <div className={styles.paraDiv}>
        <p className={styles.para}>
          {industriesData.paragraphText || "Default paragraph content."}
        </p>
      </div>
      <div className={styles.content} ref={containerRef}>
        {industriesData.industries
          .slice(0, visibleItems)
          .map((industry, index) => (
            <React.Fragment key={index}>
              <div className={styles.industry}>
                <div className={styles.title}>{industry.title}</div>
                <div className={styles.description}>{industry.description}</div>
              </div>
              <hr className={styles.divider} />
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default Industries;
