import React from "react";
import MainBlog from "./MainBlog";
import LatestPost from "./LatestPost";
import Footer from "../content/footer/Footer";
import Form from "../content/form/Form";
const BlogPage = () => {
  return (
    <div>
      <MainBlog />
      <LatestPost />
      <Form />
      <Footer />
    </div>
  );
};

export default BlogPage;
