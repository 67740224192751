// import React, { useEffect, useState } from "react";
// import styles from "./ServiceIntroText.module.css";

// const ServiceIntroText = () => {
//   const texts = [
//     {
//       big: "Expand Your Horizons. We Will Handle Your Logistics.",
//       small:
//         "Seamlessly managing every aspect of your logistics with precision and reliability, ensuring timely deliveries, cost-efficiency, and a sustainable approach to support your business growth.",
//       layout: "left",
//       id: "firstText",
//     },
//     {
//       big: "Expand Your Horizons. We Will Handle Your Logistics.",
//       small:
//         "Seamlessly managing every aspect of your logistics with precision and reliability, ensuring timely deliveries, cost-efficiency, and a sustainable approach to support your business growth.",
//       layout: "center",
//       id: "secondText",
//     },
//     {
//       big: "Your Freight, Our Priority: Secure and Efficient Transport!",
//       small:
//         "Committed to delivering secure, efficient freight transportation solutions tailored to meet the unique needs of your business.",
//       layout: "right",
//       id: "thirdText",
//     },
//   ];

//   const [currentTextIndex, setCurrentTextIndex] = useState(0);

//   //   useEffect(() => {
//   //     const interval = setInterval(() => {
//   //       setCurrentTextIndex((prev) => (prev + 1) % texts.length);
//   //     }, 4000); // Change text every 4 seconds

//   //     return () => clearInterval(interval);
//   //   }, [texts.length]);

//   const { big, small, layout, id } = texts[currentTextIndex];

//   return (
//     <div className={styles.container}>
//       <div className={`${styles.textContainer} ${styles[layout]}`}>
//         <div
//           className={`${styles.bigText} ${
//             id === "secondText" ? styles.secondTextBig : ""
//           }`}
//         >
//           {big}
//         </div>
//         <div
//           className={`${styles.smallText} ${
//             id === "firstText" ? styles.firstTextSmall : ""
//           } ${id === "thirdText" ? styles.thirdTextSmall : ""} ${
//             id === "secondText" ? styles.secondTextMargin : ""
//           }`}
//         >
//           {small}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ServiceIntroText;

import React, { useEffect, useState } from "react";
import styles from "./ServiceIntroText.module.css";
import client from "../../sanityClient"; // Adjust the import path as needed

const ServiceIntroText = () => {
  const [texts, setTexts] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    // Fetch data from Sanity
    const fetchData = async () => {
      try {
        const data = await client.fetch(`*[_type == "serviceIntroText"]`);
        setTexts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (texts.length > 0) {
      const interval = setInterval(() => {
        // setCurrentTextIndex((prev) => (prev + 1) % texts.length);
      }, 4000); // Change text every 4 seconds

      return () => clearInterval(interval);
    }
  }, [texts]);

  if (texts.length === 0) return <div></div>;

  const { big, small, layout, id } = texts[currentTextIndex];

  return (
    <div className={styles.container}>
      <div className={`${styles.textContainer} ${styles[layout]}`}>
        <div
          className={`${styles.bigText} ${
            id === "secondText" ? styles.secondTextBig : ""
          }`}
        >
          {big}
        </div>
        <div
          className={`${styles.smallText} ${
            id === "firstText" ? styles.firstTextSmall : ""
          } ${id === "thirdText" ? styles.thirdTextSmall : ""} ${
            id === "secondText" ? styles.secondTextMargin : ""
          }`}
        >
          {small}
        </div>
      </div>
    </div>
  );
};

export default ServiceIntroText;
