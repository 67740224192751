// import React, { useState, useEffect } from "react";
// import styles from "./Slider.module.css";
// import slideVideo1 from "./video1.mp4";
// import slideVideo2 from "./video1.mp4";
// import slideVideo3 from "./video1.mp4";
// import play from "./play.png";

// const videos = [slideVideo1, slideVideo2, slideVideo3];

// const SLIDE_INTERVAL = 2000; // Slide interval in milliseconds

// const Slider = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isPlaying, setIsPlaying] = useState(false);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (!isPlaying) {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
//       }
//     }, SLIDE_INTERVAL);

//     return () => clearInterval(interval); // Cleanup on unmount
//   }, [isPlaying]);

//   const togglePlay = () => {
//     setIsPlaying(!isPlaying);
//   };

//   return (
//     <div className={styles.sliderContainer}>
//       <div className={styles.sliderWrapper}>
//         <div
//           className={styles.sliderContent}
//           style={{ transform: `translateX(-${currentIndex * 100}%)` }}
//         >
//           {videos.map((video, index) => (
//             <div className={styles.videoWrapper} key={index}>
//               <div className={styles.videoOverlay} />
//               <video
//                 className={styles.video}
//                 autoPlay={isPlaying && currentIndex === index}
//                 loop
//                 muted={!isPlaying || currentIndex !== index}
//               >
//                 <source src={video} type="video/mp4" />
//                 Your browser does not support the video tag.
//               </video>
//               {!isPlaying && (
//                 <button
//                   onClick={togglePlay}
//                   className={styles.playButtonContainer}
//                 >
//                   <img className={styles.playButton} src={play} alt="play" />
//                 </button>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className={styles.indicators}>
//         {videos.map((_, index) => (
//           <div
//             key={index}
//             className={`${styles.indicator} ${
//               currentIndex === index ? styles.active : ""
//             }`}
//             onClick={() => setCurrentIndex(index)}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Slider;

import React, { useState, useEffect, useRef } from "react";
import styles from "./Slider.module.css";
import play from "./play.png";
import pause from "./pause.png"; // Add a pause icon
import client from "../../sanityClient"; // Adjust the path to your Sanity client

const SLIDE_INTERVAL = 2000; // Slide interval in milliseconds

const Slider = () => {
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRefs = useRef([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const data = await client.fetch(`*[_type == "slider"]{
        "video1": video1.asset->url,
        "video2": video2.asset->url,
        "video3": video3.asset->url
      }`);
      setVideos(
        data[0] ? [data[0].video1, data[0].video2, data[0].video3] : []
      );
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    if (!isPlaying) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
      }, SLIDE_INTERVAL);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isPlaying, videos.length]);

  useEffect(() => {
    // Manage video playback based on currentIndex and isPlaying
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === currentIndex) {
          if (isPlaying) {
            video.play();
          } else {
            video.pause();
          }
        } else {
          video.pause();
          video.currentTime = 0; // Reset playback position for non-visible videos
        }
      }
    });
  }, [currentIndex, isPlaying]);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  if (videos.length === 0) return null; // Render nothing if no videos are fetched

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderWrapper}>
        <div
          className={styles.sliderContent}
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: isPlaying ? "none" : "transform 0.5s ease", // Disable sliding transition when playing
          }}
        >
          {videos.map((video, index) => (
            <div className={styles.videoWrapper} key={index}>
              <div className={styles.videoOverlay} />
              <video
                ref={(el) => (videoRefs.current[index] = el)}
                className={styles.video}
                loop
                muted={true} // Always muted, as we are manually controlling playback
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button
                onClick={togglePlay}
                className={styles.playButtonContainer}
              >
                <img
                  className={styles.playButton}
                  src={isPlaying ? pause : play}
                  alt={isPlaying ? "pause" : "play"}
                />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.indicators}>
        {videos.map((_, index) => (
          <div
            key={index}
            className={`${styles.indicator} ${
              currentIndex === index ? styles.active : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
