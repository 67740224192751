// import React, { useState } from "react";
// import styles from "./ChooseUs.module.css";
// import choose1 from "./choose1.png";
// import choose2 from "./choose2.png";

// const ChooseUs = () => {
//   const [hoveredCard, setHoveredCard] = useState(null);

//   const handleMouseEnter = (cardIndex) => {
//     setHoveredCard(cardIndex);
//   };

//   const handleMouseLeave = () => {
//     setHoveredCard(null);
//   };

//   // Mapping card indexes to images
//   const cardImages = [choose1, choose2, choose1, choose2]; // Adjust as needed

//   return (
//     <div className={styles.chooseUs}>
//       <div className={styles.leftSection}>
//         <div className={styles.badge}>
//           <span className={styles.badgeText}>Why Choose Us</span>
//         </div>
//         <div className={styles.paraDiv}>
//           <p className={styles.description}>
//             We offer a range of freight forwarding solutions such as sea freight
//             to inland transportation for your supply chain network.
//           </p>
//         </div>
//       </div>
//       <div className={styles.imageAndCardsWrapper}>
//         <div className={styles.imageWrapper}>
//           <img
//             src={
//               hoveredCard !== null ? cardImages[hoveredCard] : cardImages[0] // Default to the first card's image
//             }
//             alt="Choose Us"
//             className={styles.image}
//           />
//         </div>
//         <div className={styles.rightSection}>
//           {[1, 2, 3, 4].map((card, index) => (
//             <div
//               key={index}
//               className={`${styles.card} ${
//                 hoveredCard === index ? styles.cardHovered : ""
//               }`}
//               onMouseEnter={() => handleMouseEnter(index)}
//               onMouseLeave={handleMouseLeave}
//             >
//               <h3 className={styles.cardHeading}>
//                 Efficient Warehousing Solutions
//               </h3>
//               <div className={styles.paraDiv2}>
//                 <p className={styles.cardDescription}>
//                   Our strategically located warehouses are equipped with
//                   advanced inventory management systems, ensuring rapid order
//                   fulfillment and reduced storage costs. Streamline your supply
//                   chain with our customizable warehousing solutions.
//                 </p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChooseUs;

import React, { useState, useEffect } from "react";
import styles from "./ChooseUs.module.css";
import client from "../../sanityClient";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

const urlFor = (source) => builder.image(source);

const ChooseUs = () => {
  const [data, setData] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "chooseUs"][0]';
      const result = await client.fetch(query);
      setData(result);
    };

    fetchData();
  }, []);

  const handleMouseEnter = (cardIndex) => {
    setHoveredCard(cardIndex);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  if (!data) return <div></div>;

  const { badgeText, description, cards, defaultImage } = data;

  return (
    <div id="why-choose-us" className={styles.chooseUs}>
      <div className={styles.leftSection}>
        <div className={styles.badge}>
          <span className={styles.badgeText}>{badgeText}</span>
        </div>
        <div className={styles.paraDiv}>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
      <div className={styles.imageAndCardsWrapper}>
        <div className={styles.imageWrapper}>
          <img
            src={
              hoveredCard !== null
                ? urlFor(cards[hoveredCard]?.image).url()
                : urlFor(defaultImage).url()
            }
            alt="Choose Us"
            className={styles.image}
          />
        </div>
        <div className={styles.rightSection}>
          {cards.map((card, index) => (
            <div
              key={index}
              className={`${styles.card} ${
                hoveredCard === index ? styles.cardHovered : ""
              }`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <h3 className={styles.cardHeading}>{card.heading}</h3>
              <div className={styles.paraDiv2}>
                <p className={styles.cardDescription}>{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
