import styles from "./Services.module.css";
import client from "../../sanityClient"; // Adjust the import path as needed
import { useEffect, useState } from "react";

const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [badgeText, setBadgeText] = useState("");
  const [viewMoreText, setViewMoreText] = useState("");
  const [viewLessText, setViewLessText] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [fadeClass, setFadeClass] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from Sanity
    const fetchData = async () => {
      try {
        const query = `{
          "services": *[_type == "service"]{
            _id,
            icon {
              asset -> {
                url
              }
            },
            name,
            description
          },
          "section": *[_type == "serviceSection"][0]{
            badgeText,
            viewMoreText,
            viewLessText
          }
        }`;
        const data = await client.fetch(query);
        setServicesData(data.services);
        setBadgeText(data.section.badgeText);
        setViewMoreText(data.section.viewMoreText);
        setViewLessText(data.section.viewLessText);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const toggleServices = () => {
    setFadeClass(showMore ? "hide" : "showMore");
    setTimeout(() => {
      setShowMore(!showMore);
      setFadeClass("");
    }, 300); // Match duration with CSS animation
  };

  const displayedServices = showMore ? servicesData : servicesData.slice(0, 6);

  if (loading) {
    return <div></div>; // You can replace this with a loading spinner or skeleton
  }

  return (
    <div className={styles.servicesContainer}>
      <div className={styles.badge}>{badgeText}</div>
      <div className={`${styles.cardsContainer} ${fadeClass}`}>
        {displayedServices.map((service) => (
          <div key={service._id} className={styles.card}>
            <img
              src={service.icon.asset.url}
              alt={service.name}
              className={styles.icon}
            />
            <div className={styles.cardName}>{service.name}</div>
            <div className={styles.cardDescription}>{service.description}</div>
          </div>
        ))}
      </div>
      <button className={styles.viewMore} onClick={toggleServices}>
        {showMore ? viewLessText : viewMoreText}
      </button>
    </div>
  );
};

export default Services;
