// import React, { useState } from "react";
// import styles from "./Form.module.css";
// import bg1 from "./bg1.png";
// import bg2 from "./bg2.png";
// import bg3 from "./bg3.png";
// import bg4 from "./bg4.png";
// import Icon from "./Icon.png";

// const Form = () => {
//   const [formData, setFormData] = useState({
//     fullName: "",
//     email: "",
//     city: "",
//     service: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission
//     console.log(formData);
//   };

//   return (
//     <div id="contact-us" className={styles.formContainer}>
//       <div className={styles.leftColumn}>
//         <h2>Get in Touch</h2>
//         <p>
//           Leverage agile frameworks to provide a robust synopsis for strategy
//           foster collaborative thinking to further the overall value.
//         </p>
//         <div className={styles.contactInfo}>
//           <div className={styles.contactRow}>
//             <div className={styles.icon}>
//               <img src={Icon} alt="icon" />
//             </div>
//             <div className={styles.contactText}>
//               <div>Email</div>
//               <div>contact@logoc.com</div>
//             </div>
//           </div>
//           <div className={styles.contactRow}>
//             <div className={styles.icon}>
//               <img src={Icon} alt="icon" />
//             </div>
//             <div className={styles.contactText}>
//               <div>Phone</div>
//               <div>+123 456 7890</div>
//             </div>
//           </div>
//           <div className={styles.contactRow}>
//             <div className={styles.icon}>
//               <img src={Icon} alt="icon" />
//             </div>
//             <div className={styles.contactText}>
//               <div>Address</div>
//               <div>123 Street, City</div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className={styles.rightColumn}>
//         <form onSubmit={handleSubmit}>
//           <div className={styles.row}>
//             <input
//               id="fullName"
//               type="text"
//               name="fullName"
//               placeholder="Full Name"
//               value={formData.fullName}
//               onChange={handleChange}
//               className={styles.inputField}
//             />
//             <input
//               type="email"
//               placeholder="Email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               className={styles.inputField}
//             />
//           </div>
//           <div className={styles.row}>
//             <input
//               type="text"
//               placeholder="City"
//               id="city"
//               name="city"
//               value={formData.city}
//               onChange={handleChange}
//               className={styles.inputField}
//             />
//             <select
//               className={styles.inputField}
//               id="service"
//               name="service"
//               value={formData.service}
//               onChange={handleChange}
//             >
//               <option value="">Service</option>
//               <option value="service1">Service 1</option>
//               <option value="service2">Service 2</option>
//               <option value="service3">Service 3</option>
//             </select>
//           </div>
//           <textarea
//             id="message"
//             name="message"
//             placeholder="Your Message"
//             value={formData.message}
//             onChange={handleChange}
//             className={styles.textArea}
//           ></textarea>
//           <button type="submit" className={styles.sendButton}>
//             Send Message
//           </button>
//         </form>
//       </div>
//       <div className={styles.imageRow}>
//         <img src={bg1} alt="background1" className={styles.image} />
//         <img src={bg2} alt="background2" className={styles.image} />
//         <img src={bg3} alt="background3" className={styles.image} />
//         <img src={bg4} alt="background4" className={styles.image} />
//       </div>
//     </div>
//   );
// };

// export default Form;
import React, { useState, useEffect } from "react";
import styles from "./Form.module.css";
import client, { urlFor } from "../../sanityClient";
import { v4 as uuid4 } from "uuid";

const Form = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    city: "",
    service: "",
    message: "",
  });
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "form"][0]`;
        const data = await client.fetch(query);
        setContent(data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load form data.");
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content) return;

    try {
      const response = await client
        .patch(content._id)
        .setIfMissing({ formSubmissions: [] })
        .append("formSubmissions", [
          {
            ...formData,
            _key: uuid4(),
            submittedAt: new Date().toISOString(),
          },
        ])
        .commit();

      if (response) {
        console.log("Form submitted successfully!", formData);
        setFormData({
          fullName: "",
          email: "",
          city: "",
          service: "",
          message: "",
        });
      } else {
        // console.error("Failed to submit form:", response.statusText);
        // setError("Failed to submit form.");
      }
    } catch (error) {
      // console.error("Error submitting form:", error);
      // setError("Error submitting form.");
    }
  };

  if (error) return <div>{error}</div>;
  if (!content) return <div></div>;

  return (
    <div id="contact-us" className={styles.formContainer}>
      <div className={styles.leftColumn}>
        <h2>{content.header}</h2>
        <p>{content.description}</p>
        <div className={styles.contactInfo}>
          {content.contactInfo?.map((item, index) => (
            <div key={index} className={styles.contactRow}>
              {item.icon && (
                <div className={styles.icon}>
                  <img src={urlFor(item.icon.asset).url()} alt="icon" />
                </div>
              )}
              <div className={styles.contactText}>
                <div>{item.label}</div>
                <div>{item.value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rightColumn}>
        <form onSubmit={handleSubmit}>
          <div className={styles.row}>
            <input
              id="fullName"
              type="text"
              name="fullName"
              placeholder={content.formFields.fullNameLabel}
              value={formData.fullName}
              onChange={handleChange}
              className={styles.inputField}
              required
            />
            <input
              type="email"
              placeholder={content.formFields.emailLabel}
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={styles.inputField}
              required
            />
          </div>
          <div className={styles.row}>
            <input
              type="text"
              placeholder={content.formFields.cityLabel}
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className={styles.inputField}
              required
            />
            <select
              className={styles.inputField}
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">{content.formFields.serviceLabel}</option>
              {/* Add service options dynamically if needed */}
              {content.services?.map((service, index) => (
                <option key={index} value={service.value}>
                  {service.name}
                </option>
              ))}
            </select>
          </div>
          <textarea
            id="message"
            name="message"
            placeholder={content.formFields.messageLabel}
            value={formData.message}
            onChange={handleChange}
            className={styles.textArea}
            required
          ></textarea>
          <button type="submit" className={styles.sendButton}>
            {content.formFields.sendButtonText}
          </button>
        </form>
      </div>
      <div className={styles.imageRow}>
        {content.images?.map((img, index) => (
          <img
            key={index}
            src={urlFor(img.asset).url()}
            alt={`background${index + 1}`}
            className={styles.image}
          />
        ))}
      </div>
    </div>
  );
};

export default Form;
