import React from "react";
import Footer from "../../content/footer/Footer";
import PrivacyPolicy from "./PrivacyPolicy";

const PrivacyPage = () => {
  return (
    <div>
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default PrivacyPage;
